import { getEnv } from "@urecruits/api";
import { useEffect, useState } from "react";
import axios from "axios";
import { DashboardEmptyDataComponent, getConfig, ShowToolTipComponent, SmallLoaderComponent } from "@ucrecruits/globalstyle/src/ucrecruits-globalstyle";
import { Link } from "@reach/router";


const { API_RECRUITMENT } = getEnv()

const dayPublishConvert = (publishDay: string): number => {
  return Math.round((Date.now() - new Date(publishDay).valueOf()) / 86400000);
};
const timeConvert = (date: string): string => {
  return new Date(date).toLocaleTimeString().replace(/:\d+ /, ' ');
}

async function changeReadStatus(item, setRefetch) {
  try {

    const data = await axios.patch(`${API_RECRUITMENT}/api/notifications/${item.id}`, { read: !item.read }, getConfig())
    if (data.status === 200) {
      setRefetch(true)
    }
  } catch (error) {
    console.log({ error })
  }
}

async function fetchNotifications() {
  try {
    const response = await axios.get(`${API_RECRUITMENT}/api/notifications`, getConfig());
    return response.data?.rows || [];
  } catch (error) {
    throw new Error("Failed to fetch notifications");
  }
}

async function markAllAsRead(setRefetch) {
  try {
    const data = await axios.patch(`${API_RECRUITMENT}/api/notifications/read/mark-all`, {}, getConfig());
    if (data.status === 200) {
      setRefetch(true)
    }
  } catch (error) {
    throw new Error("Failed to mark all notification as read");
  }
}

const Notifications = ({ setCount }) => {
  const [notifications, setNotifications] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [isEmpty, setIsEmpty] = useState(false)
  const [refetch, setRefetch] = useState(false)

  useEffect(() => {
    fetchNotificationsAndHandleState({ needLoader: false });
  }, [refetch]);

  useEffect(() => {
    fetchNotificationsAndHandleState({});
  }, []);

  const fetchNotificationsAndHandleState = async ({ needLoader = true }) => {

    needLoader && setIsLoading(true);
    try {
      const fetchedNotifications = await fetchNotifications();
      setNotifications(fetchedNotifications);
      setCount(fetchedNotifications.reduce((acc, next) => (next.read ? acc : acc + 1), 0));
      setIsEmpty(fetchedNotifications.length === 0);
      setIsLoading(false);
      refetch && setRefetch(false)
    } catch (error) {
      console.error("Error fetching notifications:", error);
      setIsEmpty(true);
      setIsLoading(false);
    }
  }

  const onStatusChange = (item) => {
    changeReadStatus(item, setRefetch)
  }
  const OnMarkAllRead = () => {
    markAllAsRead(setRefetch)
  }

  return (
    <>
      <div className="header__notification__body">
        <p className="header__notification__body__head">
          <span className="header__notification__body__head title">Notifications</span>
          {notifications?.reduce((acc, next) => (next.read ? acc : acc + 1), 0) > 0 && <span className="header__notification__body__head link" onClick={OnMarkAllRead}>Mark all as read</span>}
        </p>
        {isLoading && <SmallLoaderComponent />}
        <>
          {!isLoading && isEmpty ? <DashboardEmptyDataComponent /> :
            <div className="header__notification__body__list" >
              {
                notifications.length > 0 && notifications.map(item => (
                  <NotificationItem item={item} onStatusChange={onStatusChange} key={item.id} />
                ))
              }
            </div>
          }
        </>
      </div>
    </>
  )
}

const NotificationItem = ({ item, onStatusChange }) => {

  return <div className={`header__notification__item ${item.read ? "" : "new"}`} key={item.id} >
    <div className="header__notification__item__content">
      <Link to={item.link || "/recruitment/mail-box"}  className="header__notification__item__name">{item.title || "New Message"}</Link>
      <div className="header__notification__item__info">
        <div>

          <p className="header__notification__item__description">{item.description || ""}</p>
          <span className="header__notification__item__time">{dayPublishConvert(item.createdAt) === 0 ? "today" : dayPublishConvert(item.createdAt) + " days ago"}, {timeConvert(item.createdAt)} </span>
        </div>
        <ShowToolTipComponent text={item.read ? "Mark as unread" : "Mark as read"} position="left-start">
          <div className={`header__notification__item__actions${item.read ? " read" : ""}`} onClick={() => onStatusChange(item)}>
            {!item.read && <span className="dot"></span>}
          </div>
        </ShowToolTipComponent>
      </div>
    </div>
  </div>
}
export default Notifications;